@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 240 10% 3.9%;
    --card: 0 0% 100%;
    --card-foreground: 240 10% 3.9%;
    --popover: 0 0% 100%;
    --popover-foreground: 240 10% 3.9%;
    --primary: 147 82% 35%;
    --primary-foreground: 355.7 100% 97.3%;
    --secondary: 240 4.8% 95.9%;
    --secondary-foreground: 240 5.9% 10%;
    --muted: 240 4.8% 95.9%;
    --muted-foreground: 240 3.8% 46.1%;
    --accent: 240 4.8% 95.9%;
    --accent-foreground: 240 5.9% 10%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --border: 240 5.9% 90%;
    --input: 240 5.9% 90%;
    --ring: 142.1 76.2% 36.3%;
    --radius: 0.5rem;
    --light-primary: 159 100% 96%;
  }

  .dark {
    --background: 20 14.3% 4.1%;
    --foreground: 0 0% 95%;
    --card: 24 9.8% 10%;
    --card-foreground: 0 0% 95%;
    --popover: 0 0% 9%;
    --popover-foreground: 0 0% 95%;
    --primary: 142.1 70.6% 45.3%;
    --primary-foreground: 144.9 80.4% 10%;
    --secondary: 240 3.7% 15.9%;
    --secondary-foreground: 0 0% 98%;
    --muted: 0 0% 15%;
    --muted-foreground: 240 5% 64.9%;
    --accent: 12 6.5% 15.1%;
    --accent-foreground: 0 0% 98%;
    --destructive: 0 62.8% 30.6%;
    --destructive-foreground: 0 85.7% 97.3%;
    --border: 240 3.7% 15.9%;
    --input: 240 3.7% 15.9%;
    --ring: 142.4 71.8% 29.2%;

  }
}

@layer base {
  * {
    @apply border-border;

  }

  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

.footer-backgroud-color {
  background-color: var(--footer-backgroud);
}

.img-icon>path {
  fill: aqua;
}

.required:after {
  content: " *";
  color: red;
}

body {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

body::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.bg-light-primary {
  background-color: hsl(var(--light-primary));
  color: hsl(var(--primary));
}

.light-primary-btn {
  background-color: hsl(var(--light-primary));
  color: hsl(var(--primary));
}


.light-primary-text {
  color: hsl(var(--light-primary));
}

/* input::-ms-reveal,
input::-ms-clear {
  display: none;
} */
input:active input::-ms-reveal{
  display: block;
}
.c-table table thead th  {
  border: 1px solid  hsl(var(--primary));
  border-collapse: collapse;
  padding: 0.75rem;
}

.c-table table thead tr  {
  background: hsl(var(--primary));
  color: white;
  font-size: smaller;
}

.c-table table tbody td  {
  border: 1px solid hsl(var(--primary));
  border-collapse: collapse;
  font-size: smaller;
  padding: 1rem;
}


@layer utilities {
  .w-485 {
    width: 48%;
  }
  
}